<template>
  <v-data-table
    :items-per-page="5"
    @click:row="navigateToChargeDetails"
    :headers="headers"
    :items="data"
  ></v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      headers: [
        {
          text: 'Date',
          value: 'created_at',
        },
        {
          text: 'Minutes Charged',
          value: 'minutes',
        },
      ],
      data: [],
    }
  },

  computed: {
    ...mapGetters(['allCharges']),
  },

  mounted() {
    this.addTableData()
  },

  methods: {
    addTableData() {
      this.allCharges.forEach((charge) => {
        if (charge.minutes == null) {
          this.data.push({
            created_at: this.format(charge.created_at),
            minutes: 0 + 'm',
            id: charge.id,
          })
        } else {
          this.data.push({
            created_at: this.format(charge.created_at),
            minutes: charge.minutes + 'm',
            id: charge.id,
          })
        }
      })
    },

    navigateToChargeDetails(row) {
      this.$router.push(`/charge/${row.id}`)
    },

    format(date) {
      return dayjs(date).format('D.MMM YYYY')
    },
  },
}
</script>

<style>
</style>