<template>
  <v-list-group :value="expanded" prepend-icon="mdi-clock" no-action>
    <template slot="activator">
      <v-list-item-content>
        <v-list-item-title class="">{{ todayOpeningHours }}</v-list-item-title>
        <v-list-item-subtitle class="mb-2">{{ text }} </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="(openingHour, index) in getCurrentStation.opening_hours"
      :key="index"
    >
      <v-list-item-content>
        <v-list-item-title class=""
          >{{ openingHour.from | formatTimes }} -
          {{ openingHour.to | formatTimes }}</v-list-item-title
        >
        <v-list-item-subtitle class="mb-2"
          >{{ openingHour.day_of_the_week }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
   filters: {
    formatTimes: function (value) {
      if (!value) {
        return ''
      }
      value.split(':', 2)
      let formattedTime = value[0] + value[1] + ':' + value[3] + value[4]
      return formattedTime
    },
  },
  
  data() {
    return {
      expanded: false,
      text: 'Today',
      currentDay: null,
      currentOpeningHour: null,
      currentClosingHour: null,
      open: false,
    }
  },

  computed: {
    ...mapGetters(['getCurrentStation']),
    todayOpeningHours() {
      if (!this.currentOpeningHour) {
        return 'Station not open'
      }
      return this.currentOpeningHour + ' - ' + this.currentClosingHour
    },
  },

  async mounted() {
    await this.fetchCurrentStationByHumanSerial(this.$route.params.humanSerial)
    this.checkIfOpen()
  },

  methods: {
    ...mapActions(['fetchCurrentStationByHumanSerial']),
    checkIfOpen() {
      const now = dayjs()
      const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wedensday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      for (const openingHour of this.getCurrentStation.opening_hours) {
        let openingTime = openingHour.from.split(':', 2)
        let openingHours = openingTime[0].split()
        let openingMinutes = openingTime[1]
        let dayJsOpeningHours = dayjs().hour(openingHours)
        let dayJsOpeningHoursWithMinutes = dayjs(dayJsOpeningHours).minute(
          openingMinutes
        )
        let closingTime = openingHour.to.split(':', 2)
        let closingHours = closingTime[0].split()
        let closingMinutes = closingTime[1]
        let dayJsClosingHours = dayjs().hour(closingHours)
        let dayJsClosingHoursWithMinutes = dayjs(dayJsClosingHours).minute(
          closingMinutes
        )

        for (let i = 0; i < days.length; i++) {
          const day = days[i]
          if (day == openingHour.day_of_the_week) this.currentDay = i
        }
        if (
          now.day() == this.currentDay &&
          now > dayJsOpeningHoursWithMinutes &&
          now < dayJsClosingHoursWithMinutes
        ) {
          this.currentOpeningHour = openingHours + ':' + openingMinutes
          this.currentClosingHour = closingHours + ':' + closingMinutes
          this.open = true
        } else if (
          now.day() == this.currentDay &&
          now < dayJsOpeningHoursWithMinutes &&
          now < dayJsClosingHoursWithMinutes
        ) {
          this.text = 'Today - opens later'
        }
      }
    },
  },
}
</script>

<style>
</style>
