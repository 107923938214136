import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'
import dayjs from 'dayjs'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

store.$http.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('logout')
      router.push('/login')
      return Promise.resolve()
    }

    return Promise.reject(error)
  }
})

new Vue({
  router,
  store,
  dayjs,
  vuetify,
  render: h => h(App),
}).$mount('#app')
