<template>
  <v-container fill-height align-start fluid>
    <v-snackbar v-model="this.getFavoriteAdded" timeout="2000">
      {{this.getFavoriteMessage}}
    </v-snackbar>
    <v-row justify="end">
      <v-col cols="1" class="no-padding-help-col mr-3">
        <v-btn icon to="/help" class="home-help-icon"
          ><v-icon class="">mdi-help-circle-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="getFavorites.length > 0">
      <v-col justify="center" align="center" :lg="4" :md="6" :sm="8" cols="8">
        <favorite-stations/>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col justify="center" align="center" :lg="4" :md="6" :sm="8" cols="8">
        <input-station />
        <v-btn color="primary" to="/map"  ><v-icon class="mr-2">mdi-map</v-icon> To map</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputStation from '../components/home/InputStation.vue'
import FavoriteStations from "../components/home/FavoriteStations";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {
    InputStation,
    FavoriteStations
  },
  mounted() {
    this.fetchStations();
    this.fetchUserFavorites();
  },
  computed:{
    ...mapGetters(['getFavoriteAdded','getFavoriteMessage','getFavorites'])
  },
  methods:{
    ...mapActions(['fetchUserFavorites','fetchStations'])
  }

}
</script>

<style scoped>

.no-padding-help-col {
  padding: 0  !important;
  margin-left: 100px
}
.home-help-icon {
  float: right;
  
}
</style>
