/* eslint-disable no-unused-vars */

import * as http from "http";

const state = {
  user: {},
  favorites: [],
  error: null,
  favoriteAdded: false,
  favoriteMessage: ""
}

const getters = {
  currentUser: state => state.user,
  currentError: state => state.error,
  getFavorites: state => state.favorites,
  getFavoriteAdded: state => state.favoriteAdded,
  getFavoriteMessage: state => state.favoriteMessage,
}

const actions = {
  async fetchUser({ commit }) {
    await this.$http
      .get('/user')
      .then(response => {
        if (response) {
          commit('setUser', response.data)
        }
      })
      .catch(error => {
        commit('setError', error.response.status)
      })
  },
  updateUser({ commit }, user) {
    return this.$http
      .put(`/users/${user.id}`, user)
      .then(function(response) {
        commit('setUser', response.data)
      })
      .catch(function(error) {
        commit('setError', error.response.status)
      })
  },

  async updateError({ commit }, payload) {
    commit('setError', payload)
  },

  async initUserEventsChannel({ commit }, userId) {
    this.$userEventsChannel = this.$echo.private('user.' + userId + '.events')
  },

  subscribeToUserUpdates({ commit }) {
    this.$userEventsChannel.listen('UserUpdated', payload => {
      commit('setUser', payload.user)
    })
  },

  async fetchUserFavorites({commit}){
    await this.$http.get(`/user?withFavorites`)
        .then(response => {
          response.data.favorites.filter(obj => {
            commit('setFavorites',{id:obj.id, station_id: obj.station_id});
          })
        })
  },


  async addUserFavorite({commit},stationId) {
    const favorite = {user_id: state.user.id, station_id: stationId}
    await this.$http.post('/favorites',favorite).then((response) => {
      commit("setFavoriteMessage","Station added to favorites")
      commit("setFavoriteAdded",true)
      commit("setFavorites",{id: response.data.id, station_id: response.data.station_id})
      setTimeout(() => {commit("setFavoriteAdded",false)},2000)
    }).catch((e) => {
      commit("setFavoriteMessage",e.message)
      commit("setFavoriteAdded",true)
      setTimeout(() => {commit("setFavoriteAdded",false)},2000)
    })
  },

  async destroyUserFavorite({commit},id){
    await this.$http.delete(`/favorites/${id}`).then(() => {
      commit("setFavoriteMessage","Station deleted from favorites")
      commit("setFavoriteAdded",true)
      commit("removeUserFavorite",id)
      setTimeout(() => {commit("setFavoriteAdded",false)},2000)
    }).catch((e) => {
      commit("setFavoriteMessage",e.message)
      commit("setFavoriteAdded",true)
      setTimeout(() => {commit("setFavoriteAdded",false)},2000)
    })
  }
}

const mutations = {
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setFavorites(state, payload) {
    state.favorites.push(payload)
  },
  setFavoriteAdded(state, payload){
    state.favoriteAdded = payload
  },
  setFavoriteMessage(state, payload){
    state.favoriteMessage = payload
  },
  removeUserFavorite(state,payload){
   state.favorites = state.favorites.filter((fav) => {
     return fav.id !== payload
   })
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
