<template>
  <div>
    <v-speed-dial
      v-model="isOpen"
      fixed
      right
      bottom
      class="fab"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-fab-transition>
          <v-badge
            :content="Object.keys(activeCharges).length"
            :value="Object.keys(activeCharges).length"
            color="secondary"
            offset-x="16"
            offset-y="16"
            :key="fabIcon"
            v-if="show"
          >
            <v-btn v-model="isOpen" fab color="primary">
              <v-icon v-if="isOpen"> mdi-close</v-icon>
              <v-icon v-else> {{ fabIcon }}</v-icon>
            </v-btn>
          </v-badge>
        </v-fab-transition>
      </template>
      <v-btn fab small class="fab primary" to="/"
        ><v-icon>mdi-ev-station</v-icon></v-btn
      >
      <v-badge
        color="secondary"
        :content="currentUser.points + 'p'"
        offset-x="30"
        offset-y="20"
      >
        <v-btn fab small class="primary" to="/payment"
          ><v-icon>mdi-currency-usd</v-icon></v-btn
        >
      </v-badge>
    </v-speed-dial>
    <v-slide-x-reverse-transition>
      <v-speed-dial
        v-show="isOpen"
        v-if="isOpen"
        :value="true"
        fixed
        direction="left"
        right
        bottom
      >
        <template v-slot:activator>
          <v-btn class="fab_hidden" fab> </v-btn>
        </template>

        <v-badge
          :content="activeCharge.charging_port.charging_port_number"
          color="secondary"
          offset-x="20"
          offset-y="20"
          v-for="(activeCharge, index) in activeCharges"
          :key="index"
          v-show="isOpen"
        >
          <v-btn fab small class="primary" :to="`/charge/` + activeCharge.id"
            ><v-icon>mdi-flash</v-icon></v-btn
          >
        </v-badge>
      </v-speed-dial>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FabMenu',

  data: () => ({
    isOpen: false,
    show: false,
  }),

  computed: {
    ...mapGetters(['currentUser', 'activeCharges']),
    chargeIsActive() {
      return Object.keys(this.activeCharges).length != 0
    },
    fabIcon() {
      if (!this.show) {
        return null
      }
      if (this.chargeIsActive) {
        return 'mdi-flash'
      }
      return 'mdi-plus'
    },
  },

  created() {
    this.fetchActiveCharges()
  },

  mounted() {
    this.show = true
  },

  methods: {
    ...mapActions(['fetchActiveCharges']),
  },
}
</script>

<style scoped>
.fab {
  z-index: 9 !important;
}
.fab_hidden {
  visibility: hidden;
  z-index: 8 !important;
}
</style>