<template>
  <v-tabs color="primary">
    <v-tab>Charges</v-tab>
    <v-tab>Payments</v-tab>
    <v-tab-item>
      <table-charges />
    </v-tab-item>
    <v-tab-item>
      <table-payments />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import TableCharges from './TableCharges.vue'
import TablePayments from './TablePayments.vue'
export default {
  components: { TableCharges, TablePayments },
}
</script>

<style>
</style>