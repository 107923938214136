<template>
  <v-app-bar height="66" fixed app clipped-left>
    <img
      height="30"
      src="../assets/mPowerStation.png"
      alt="mPowerStation Logo"
      @click="goToHome"
      class="cursor-pointer"
    />
    <v-spacer></v-spacer>
    <slot />
    <v-spacer></v-spacer>
    <v-icon class="mr-2">mdi-theme-light-dark</v-icon>
    <v-switch
      v-model="$vuetify.theme.dark"
      @click="toggleDarkMode"
      inset
      class="mt-5"
    >
    </v-switch>
    <user-drop-down v-if="isAuthenticated"></user-drop-down>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import UserDropDown from './user/UserDropDown.vue'

export default {
  components: { UserDropDown },

  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
  },

  methods: {
    goToHome() {
      this.$router.push('/')
    },
    toggleDarkMode() {
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffff !important;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.pill {
  background-color: #8eb360;
  color: white !important;
  border: 1px solid #8eb360;
  border-radius: 20px;
  padding: 3px;
  height: 32px;
}
</style>
