<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center" class="loading-container">
        <v-progress-circular
          :size="100"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p>Payment pending</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentPayment']),
  },

  created() {
    this.checkPaymentStatus()
  },

  methods: {
    ...mapActions(['fetchPayment']),
    async checkPaymentStatus() {
      await this.fetchPayment({ id: this.$route.params.id })
      if (this.currentPayment.status_code_id === 1) {
        setTimeout(
          () => {
            this.checkPaymentStatus(this.$route.params.id)
          },
          1000,
          this
        )
        return
      }
      if (this.currentPayment.status_code_id !== 2) {
        this.error = true
        this.$router.go(-1)
        return
      }
      this.$router.push('/payment/' + this.currentPayment.id)
      return
    },
  },
}
</script>

<style scoped>
.loading-container {
  position: relative;
  height: 100px;
  margin-top: 20%;
}
</style>
