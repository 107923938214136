<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center" v-if="checkIfPaymentMethods">
        <payment-skeleton />
      </v-col>
      <v-col align="center" justify="center" v-else>
        <v-row align="center" justify="center">
          <v-col align="center" justify="center">
            <h1 class="title">Buy Points</h1>
            <v-slider
              class="slider-max-width"
              v-model="amount"
              thumb-label="always"
              color="primary"
              track-color="grey"
              always-dirty
              min="1"
              max="500"
            >
              <template v-slot:prepend>
                <v-icon color="primary" @click="decrement"> mdi-minus </v-icon>
              </template>

              <template v-slot:append>
                <v-icon color="primary" @click="increment"> mdi-plus </v-icon>
              </template>
            </v-slider>
            <h3>Cost</h3>
            <p>{{ cost }} {{ currency.short_name }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <p v-if="paymentMethods.length <= 0">
              No payment methods available in your country
            </p>
            <v-radio-group v-else mandatory v-model="paymentMethodId">
              <v-radio
                v-for="paymentMethod in paymentMethods"
                :key="paymentMethod.id"
                :label="paymentMethod.name"
                :value="paymentMethod.id"
                @input="message = ''"
              ></v-radio> </v-radio-group
          ></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="px-14" color="primary" x-large @click.once="pay"
              >Pay</v-btn
            >
            <p class="warning">{{ message }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PaymentSkeleton from './PaymentSkeleton.vue'

export default {
  components: {
    PaymentSkeleton,
  },

  data() {
    return {
      amount: 10,
      paymentMethodId: null,
      message: null,
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'currency', 'paymentMethods']),
    cost() {
      return this.amount * this.currency.price_per_point
    },

    checkIfPaymentMethods() {
      if (this.paymentMethods.length == null) {
        return true
      }
      return false
    },
  },

  created() {
    this.fetchCurrency()
    this.fetchPaymentMethods()
  },

  methods: {
    ...mapActions(['initiatePayment', 'fetchCurrency', 'fetchPaymentMethods']),
    async pay() {
      if (!this.paymentMethodId) {
        this.message = 'Please select payment method'
        return
      }
      let payment = await this.initiatePayment({
        points: this.amount,
        paymentMethodId: this.paymentMethodId,
      })
      if (payment.status_code_id === 3) {
        this.message = 'Payment could not be processed.'
        return
      }
      this.$router.push('payment/pending/' + payment.id)
    },
    decrement() {
      this.amount--
    },
    increment() {
      this.amount++
    },
  },
}
</script>

<style scoped>
.slider-max-width {
  max-width: 70%;
}
</style>


