import { setTimeout } from 'core-js'
import store from '..'
import Vue from 'vue'

const state = {
  currentCharge: {},
  activeCharges: {},
  allCharges: {},
  errorMessage: null,
  counters: {},
}

const getters = {
  currentCharge: state => state.currentCharge,
  activeCharges: state => state.activeCharges,
  allCharges: state => state.allCharges,
  getErrorMessage: state => state.errorMessage,
  getTimeRemainingOfCurrentCharge: state => {
    return state.counters[state.currentCharge.id]
      ? state.counters[state.currentCharge.id].timeRemaining
      : null
  },
}

const actions = {
  async startCharge({ commit }, { stationId, minutes }) {
    try {
      const response = await this.$http.post('/charges', {
        station_id: stationId,
        minutes: minutes,
      })
      commit('setActiveCharge', response.data)
      return response.data.id
    } catch (error) {
      commit('setErrorMessage', error.response.data.message)
    }
  },

  async fetchCurrentCharge({ commit }, id) {
    if (state.currentCharge.id !== id) {
      commit('setCurrentCharge', {})
    }
    const response = await this.$http.get(`/charges/${id}?withChargingPort`)
    commit('setCurrentCharge', response.data)
  },
  async updateActiveCharge({ commit }, id) {
    const response = await this.$http.get(`/charges/${id}?withChargingPort`)
    if (state.currentCharge.id === response.data.id) {
      commit('setCurrentCharge', response.data)
    }
    commit('setActiveCharge', response.data)
  },

  async fetchActiveCharges({ commit }) {
    const response = await this.$http.get(`/charges?withChargingPort&isActive`)
    if (!response.data) {
      return
    }
    response.data.forEach(charge => {
      commit('setActiveCharge', charge)
    })
  },

  async stopCharge({ commit }, payload) {
    const response = await this.$http.post(`/charges/${payload}/stop`)
    commit('removeActiveCharge', payload)
    commit('setCurrentCharge', response.data)
    clearTimeout(state.counters[payload].timeoutRef)
  },

  async fetchAllCharges({ commit }) {
    const response = await this.$http.get('/charges')
    commit('setAllCharges', response.data)
  },

  resetErrorMessage({ commit }, payload) {
    commit('setErrorMessage', payload)
  },

  notificationAtEndCountDown() {
    if (!('serviceWorker' in navigator)) {
      return
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(swreg => {
        let data = { url: '/' }
        let options = {
          body: 'Please unplug your device',
          vibrate: [100, 50, 200],
          data: {
            url: data.url,
          },
        }
        swreg.showNotification('Charge finished', options)
      })
    }
  },

  counting({ commit }, charge) {
    if (!charge.seconds_left) {
      return
    }
    let millisecondsLeft = charge.seconds_left * 1000
    let timeRemaining =
      millisecondsLeft - 1000 > 0 ? millisecondsLeft - 1000 : 0
    charge.seconds_left--
    if (charge.seconds_left == 120) {
      store.dispatch('updateActiveCharge', charge.id)
    }
    commit('setTimeRemaining', {
      chargeId: charge.id,
      time: timeRemaining,
    })
    if (timeRemaining <= 0) {
      commit('removeActiveCharge', charge.id)
      store.dispatch('notificationAtEndCountDown')
      return
    }
    commit('setTimeoutRef', {
      chargeId: charge.id,
      newTimeotRef: setTimeout(() => {
        store.dispatch('counting', charge)
      }, 1000),
    })
  },

  subscribeToUserCharges({ commit }) {
    this.$userEventsChannel.listen('ChargeCreatedOrUpdated', payload => {
      if (state.currentCharge.id === payload.charge.id) {
        commit('setCurrentCharge', payload.charge)
      }
      commit('updateActiveCharge', payload.charge)
    })
  },
}

const mutations = {
  setCurrentCharge: (state, currentCharge) => {
    state.currentCharge = currentCharge
  },
  updateActiveCharge: (state, charge) => {
    if (charge.status === 'FINISHED') {
      store.commit('removeActiveCharge', charge.id)
      return
    }
    store.commit('setActiveCharge', charge)
  },
  setActiveCharge: (state, activeCharge) => {
    Vue.set(state.activeCharges, activeCharge.id, activeCharge)
    store.dispatch('counting', activeCharge)
  },
  removeActiveCharge: (state, id) => {
    Vue.delete(state.activeCharges, id)
  },
  setAllCharges: (state, payload) => (state.allCharges = payload),
  setErrorMessage: (state, payload) => (state.errorMessage = payload),
  setTimeRemaining: (state, { chargeId, time }) => {
    Vue.set(state.counters, [chargeId], {
      ...state.counters[chargeId],
      timeRemaining: time,
    })
  },
  setTimeoutRef: (state, { chargeId, newTimeotRef }) => {
    clearTimeout(state.counters[chargeId].timeoutRef)
    Vue.set(state.counters, [chargeId], {
      ...state.counters[chargeId],
      timeoutRef: newTimeotRef,
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
