<template>
  <v-container v-if="currentCharge != null">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <h1 class="title">{{ headline }}</h1>

        <v-icon>mdi-ev-station</v-icon>
        <p class="station-serial">
          Station: {{ getCurrentStation.human_serial }}
        </p>
        <v-icon>mdi-usb-port</v-icon>
        <p>Port: {{ currentCharge.charging_port.charging_port_number }}</p>
        <v-icon>mdi-circle-slice-5</v-icon>
        <p>
          <small class="charge-date">{{ currentCharge.end_at | date }}</small>
        </p>
        <p>
          <b class="charge-hour" v-if="currentCharge.start_at">{{
            currentCharge.start_at | hour
          }}</b>
          <b class="charge-hour" v-else>--:--</b>
          -
          {{ hours }}h:{{ minutes }}m -
          <b class="charge-hour">{{ currentCharge.end_at | hour }}</b>
        </p>
        <v-btn icon @click="goBack"
          ><v-icon size="50">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  filters: {
    date: function (date) {
      return dayjs(date).format('MMMM D YYYY')
    },
    hour: function (date) {
      return dayjs(date).format('HH:mm')
    },
  },

  data() {
    return {
      charge: null,
      time: null,
    }
  },

  props: {
    successful: Boolean,
  },

  computed: {
    ...mapGetters(['currentCharge', 'getCurrentStation']),
    headline() {
      if (!this.successful) {
        return 'Charging failed'
      }
      return dayjs(this.currentCharge.end_at).isBefore(
        dayjs().subtract(1, 'minute')
      )
        ? 'Charge details'
        : 'Charge finished'
    },

    hours() {
      const start = dayjs(this.currentCharge.start_at)
      const end = dayjs(this.currentCharge.end_at)
      return end.diff(start, 'h') ? end.diff(start, 'h') : 0
    },
    minutes() {
      const start = dayjs(this.currentCharge.start_at)
      const end = dayjs(this.currentCharge.end_at)
      return end.diff(start, 'm') ? end.diff(start, 'm') : 0
    },
  },
  methods: {
    goBack() {
      this.$router.push('/history')
    },
  },
}
</script>

<style scoped>
.charge-detail-title-wrapper {
  margin-bottom: 30px;
}
.title.is4 {
  overflow: hidden;
}
.station-serial {
  margin-bottom: 10px;
}
.time-icon {
  margin-top: 25px;
}
.charge-date {
  font-size: 8px;
}
.charge-hour {
  font-size: 32px;
}
</style>
