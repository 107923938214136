<template>
  <v-autocomplete
    :items="allStations"
    item-text="human_serial"
    item-value="human_serial"
    outlined
    dense
    label="Station number"
    append-icon="mdi-magnify"
    autofocus
    type="text"
  >
    <template v-slot:item="{ item, on }" >

      <v-icon class="pr-4 pl-1"
              v-if="getFavorites.map(fav => fav.station_id).includes(item.id)"
              @click="deleteByItemId(item.id)">
        mdi-star-remove
      </v-icon>

      <v-icon v-else class="pr-4 pl-1" @click="addUserFavorite(item.id);" >
        mdi-star-plus-outline
      </v-icon>

      <v-list-item three-line v-on="on" :to="'/stations/' + item.human_serial">
        <v-list-item-content class="bottomBorder">
          <v-list-item-title v-html="item.human_serial"></v-list-item-title>
          <v-list-item-subtitle
            v-html="'Free ports: ' + item.number_of_free_ports"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            v-html="'Price per minute: ' + item.minutes_per_point + 'p'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['allStations', 'getFavorites']),
  },
  mounted() {
    console.log("Fav: ", this.getFavorites)
    this.askForPermission()
  },

    methods: {
      ...mapActions(['addUserFavorite', 'destroyUserFavorite']),
      askForPermission() {
        return new Promise(function (resolve, reject) {
          const permissionResult = Notification.requestPermission(function (
              result
          ) {
            resolve(result)
          })

          if (permissionResult) {
            permissionResult.then(resolve, reject)
          }
        }).then(function (permissionResult) {
          if (permissionResult !== 'granted') {
            throw new Error("We weren't granted permission.")
          }
        })
      },

      deleteByItemId(itemid){
        if (this.getFavorites.length > 1){
          this.getFavorites.reduce((fav) => {
            if (fav.station_id === itemid)
              this.destroyUserFavorite(fav.id)
          })
        }
        this.destroyUserFavorite(this.getFavorites[0].id)
      }
    },
}
</script>

<style scoped>
.bottomBorder {
  border-bottom: 1px solid black;
}
</style>
<style>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: scale(1.3) !important;
}
</style>
