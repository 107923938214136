import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import stations from './modules/stations'
import charge from './modules/charge'
import auth from './modules/auth'
import user from './modules/user'
import paymentIn from './modules/paymentIn'
import currencies from './modules/currencies'
import paymentMethods from './modules/paymentMethods'
import Echo from 'laravel-echo'

Vuex.Store.prototype.$http = axios
const token = localStorage.getItem('token')
if (token) {
  Vuex.Store.prototype.$http.defaults.headers.common['Authorization'] =
    'Bearer ' + token
}
Vuex.Store.prototype.$http.defaults.headers.common['Content-Type'] =
  'application/json'
Vuex.Store.prototype.$http.defaults.headers.common['Accept'] =
  'application/json'
Vuex.Store.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL

window.Pusher = require('pusher-js')
// Vuex.Store.prototype.$echo = new Echo({
//   broadcaster: 'pusher',
//   authEndpoint: process.env.VUE_APP_LOCAL
//     ? 'http://127.0.0.1:8000/broadcasting/auth'
//     : 'https://api.mpowerstation.com/broadcasting/auth',
//   auth: {
//     headers: {
//       Authorization: 'Bearer ' + token,
//     },
//   },
//   key: process.env.VUE_APP_PUSHER_KEY,
//   wsHost: process.env.VUE_APP_LOCAL ? 'localhost' : 'api.mpowerstation.com',
//   wsPort: process.env.VUE_APP_LOCAL ? 6001 : 6001,
//   wssPort: process.env.VUE_APP_LOCAL ? 6001 : 6001,
//   forceTLS: process.env.VUE_APP_LOCAL ? false : true,
//   disableStats: true,
//   enabledTransports: ['ws', 'wss'],
// })

Vuex.Store.prototype.$echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: 'https://api.mpowerstation.com/broadcasting/auth',
    auth: {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    stations,
    charge,
    auth,
    user,
    paymentIn,
    currencies,
    paymentMethods,
  },
})
