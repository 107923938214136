<template>
  <v-container>
    <v-row>
      <v-col justify="center" align="center">
        <h1 class="title">Charging</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col justify="center" align="center">
        <p>
          on <b class="biggerText">port {{ currentCharge.charging_port.charging_port_number }}</b>
          <br />
          at mPowerStation
          <b>{{ getCurrentStation.human_serial }}</b>
        </p>
      </v-col>
    </v-row>
    <timer-component
      @done="$emit('done')"
      :timeRemaining="getTimeRemainingOfCurrentCharge"
      :totalMinutes="currentCharge.minutes"
    />
    <v-row>
      <v-col justify="center" align="center">
        <p v-if="currentCharge.status === 'IDLE'">
          No devices connected.
          <br />
          If you are done charging, you shold consider clicking "Stop Charge"
          below.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col justify="center" align="center">
        <v-btn
          aria-label="stop charge"
          @click="stopCurrentCharge"
          color="primary"
          text
          elevation="3"
          class="my-5"
        >
          Stop Charge
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TimerComponent from './TimerComponent.vue'

export default {
  components: {
    TimerComponent,
  },

  computed: {
    ...mapGetters([
      'currentCharge',
      'getCurrentStation',
      'getTimeRemainingOfCurrentCharge',
    ]),
  },

  methods: {
    ...mapActions(['stopCharge', 'fetchCurrentCharge']),
    async stopCurrentCharge() {
      let id = this.currentCharge.id
      await this.stopCharge(id)
    },
  },
}
</script>
<style scoped>
.active-charge-info {
  margin-top: 10px;
  margin-bottom: 20px;
}
.stop-charge-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.biggerText {
    font-size: 1.2em;
}
</style>