import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import MapView from '../views/MapView.vue'
import LoginView from '../views/LoginView.vue'
import UserView from '../views/UserView.vue'
import ChargeView from '../views/ChargeView.vue'
import HomeView from '../views/HomeView.vue'
import StationView from '../views/StationView.vue'
import UserEdit from '../components/user/UserEdit.vue'
import PaymentView from '../views/PaymentView.vue'
import PaymentForm from '../components/payment/PaymentForm.vue'
import PaymentPending from '../components/payment/PaymentPending.vue'
import PaymentDetails from '../components/payment/PaymentDetails.vue'
import PageNotFound from '../components/PageNotFound.vue'
import HistoryView from '../views/HistoryView.vue'
import HelpView from '../views/HelpView.vue'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  const loginpath = window.location.pathname
  if (loginpath == null) {
    store.dispatch('updateOriginalRoute', '/')
  }
  store.dispatch('updateOriginalRoute', loginpath)
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/home',
    name: 'home',
    alias: '/',
    component: HomeView,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/map',
    name: 'map',
    component: MapView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/user',
    component: UserView,

    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'editUser',
        path: 'edit',
        component: UserEdit,
      },
    ],
  },
  {
    path: '/payment',
    component: PaymentView,
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: 'payment',
        path: '',
        component: PaymentForm,
      },
      {
        path: 'pending/:id',
        component: PaymentPending,
      },
      {
        name: 'paymentDetails',
        path: ':id',
        component: PaymentDetails,
      },
    ],
  },
  {
    path: '/stations/:humanSerial',
    component: StationView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/charge/:chargeId',
    component: ChargeView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/history',
    name: 'history',
    alias: '/',
    component: HistoryView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/help',
    name: 'help',
    component: HelpView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
