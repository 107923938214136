<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center" v-if="allCharges.length == null && allCharges.length == null">
        <h1 class="title">History</h1>
        <skelton-history />
      </v-col>
      <v-col align="center" justify="center" v-else>
        <h1 class="title">History</h1>
        <tabs-history />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import TabsHistory from '../components/history/TabsHistory.vue'
import SkeltonHistory from '../components/history/SkeltonHistory.vue'

export default {
  components: {
    TabsHistory,
    SkeltonHistory,
  },

  computed: {
    ...mapGetters(['allPayments', 'allCharges']),
  },

  created() {
    this.fetchAllPayments()
    this.fetchAllCharges()
  },

  methods: {
    ...mapActions(['fetchAllPayments', 'fetchAllCharges']),
  },
  
}
</script>

<style></style>
