<template>
  <v-container>
    <v-row>
      <v-col justify="center" align="center">
        <p>
          <b class="biggerText">Port {{ currentCharge.charging_port.charging_port_number }}</b>
          <br />
          at station
          <b>{{ getCurrentStation.human_serial }}</b>
          is now opened.
          <br />
          Please connect your device to start charging.
        </p>
      </v-col>
    </v-row>
    <timer-component
      @done="$emit('done')"
      :timeRemaining="timeRemaining"
      :totalMinutes="1"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TimerComponent from './TimerComponent.vue'
export default {
  components: {
    TimerComponent
  },

  data() {
    return {
      timeRemaining: 60,
    }
  },
 
  computed: {
    ...mapGetters(['currentCharge', 'getCurrentStation']),
  },
   created() {
    this.countdown()
  },

  methods: {
    countdown() {
      let startTime = new Date().getTime()
      let end_at = new Date(this.currentCharge.start_deadline).getTime()
      this.timeRemaining = end_at - startTime > 0 ? end_at - startTime : 0
      if (this.timeRemaining <= 0) {
        return
      }
      this.timeoutRef = setTimeout(() => {
        this.countdown()
      }, 1000)
    },
    
  },
}
</script>

<style scoped>
  .biggerText {
    font-size: 1.2em;
  }
</style>
