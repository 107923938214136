<template>
  <v-container>
    <h1>Station {{ getCurrentStation.human_serial }}</h1>
    <v-list two-line>
      <opening-hours-list />
      <v-list-item>
        <v-list-item-icon>
          <v-icon> mdi-usb-port </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="">{{
            getCurrentStation.number_of_free_ports
          }}</v-list-item-title>
          <v-list-item-subtitle class="">Ports free </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon> mdi-currency-usd </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="">{{
            getCurrentStation.minutes_per_point
          }}</v-list-item-title>
          <v-list-item-subtitle class=""
            >Minutes per point
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-slider
      v-model="minutes"
      class="mt-4"
      :max="max"
      :min="min"
      hide-details="auto"
      thumb-label="always"
      :rules="minutesRules"
      :hint="hint"
      persistent-hint
      label="Minutes to charge"
    />
    <v-btn
      :disabled="error"
      @click="charge"
      color="primary"
      class="my-5"
      >Charge
    </v-btn>
    <v-btn
      v-if="price > currentUser.points"
      aria-label="buy points"
      @click="goToPayments"
      text
      color="primary"
      class="my-5"
      >Buy points</v-btn
    >
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OpeningHoursList from './OpeningHoursList.vue'
export default {
  components: {
    OpeningHoursList,
  },

  data() {
    return {
      minutes: 30,
      notEnoughPoints: false,
      min: 1,
      max: 60,
      minutesRules: [
        (v) => !!v || 'This field is required',
        (v) => (v && v >= this.min) || 'Minimum charging time is 1 minute',
        (v) => (v && v <= this.max) || 'Max charging time is 200 minutes',
        (v) =>
          (v && this.price <= this.currentUser.points) ||
          'You dont have enough points',
      ],
    }
  },

  computed: {
    ...mapGetters([
      'getCurrentStation',
      'activeCharges',
      'getErrorMessage',
      'currentUser',
      'getError',
    ]),
    hint() {
      return 'Price: ' + this.price + ' points '
    },
    price() {
      return Math.ceil(this.minutes / this.getCurrentStation.minutes_per_point)
    },
    error() {
      if (this.currentUser.points < this.price) {
        return true
      }
      if (this.getCurrentStation.number_of_free_ports <= 0) {
        return true
      }
      return false
    },
  },

  created() {
    this.fetchCurrentStationByHumanSerial(this.$route.params.humanSerial)
    this.fetchActiveCharges()
  },

  methods: {
    ...mapActions([
      'startCharge',
      'fetchActiveCharges',
      'fetchCurrentStationByHumanSerial',
      'resetErrorMessage',
    ]),
    async charge() {
      if (this.minutes <= 0 && this.price <= 0) {
        return
      }
      let id = await this.startCharge({
        stationId: this.getCurrentStation.id,
        minutes: this.minutes,
      })
      this.$router.push(`/charge/${id}`)
    },

    goToPayments() {
      this.$router.push('/payment')
    },
  },
}
</script>

<style>
</style>
