<template>
  <div class="m-2">
    <p class="text-body-1">Favorized Stations</p>
    <v-col>
      <v-row class="justify-center">
        <div v-for="fav in getFavorites" :key="fav.id" class="mr-4 mb-6">
          <div v-for="stat in allStations" :key="stat.id">
            <div v-if="stat.id === fav.station_id">
              <router-link :to="`/stations/${stat.human_serial}`" v-if="stat.is_online === 1">
                <v-badge  overlap left bordered color="green">
                  <v-card flat outlined :width="vcardWidth" :height="vcardHeight">
                    <v-icon class="float-right mr-2 mt-2" :size="vcardIcon"
                            @click="destroyUserFavorite(fav.id)">
                      mdi-delete-circle-outline
                    </v-icon>
                    <v-card-title class="text-caption mb-4" v-if="stat.name">
                      Station - {{stat.name}}
                    </v-card-title>
                    <v-card-title class="text-caption mb-4" v-else>
                      Station - {{stat.human_serial}}
                    </v-card-title>
                    <v-card-subtitle class="mr-2 mb-n7 text-caption">Free ports: {{stat.number_of_free_ports}}</v-card-subtitle>
                    <v-card-subtitle class="mr-2 text-caption">Price per minute: {{stat.minutes_per_point}}p</v-card-subtitle>
                  </v-card>
                </v-badge>
              </router-link>
              <v-badge  overlap left bordered color="red" v-else>
                <v-card flat outlined :width="vcardWidth" :height="vcardHeight">
                  <v-icon class="float-right mr-2 mt-2" :size="vcardIcon"
                          @click="destroyUserFavorite(fav.id)">
                    mdi-delete-circle-outline
                  </v-icon>
                  <v-card-title class="text-caption mb-4" v-if="stat.name">
                    Station - {{stat.name}}
                  </v-card-title>
                  <v-card-title class="text-caption mb-4" v-else>
                    Station - {{stat.human_serial}}
                  </v-card-title>
                  <v-card-subtitle class="mr-2 mb-n7 text-caption">Free ports: {{stat.number_of_free_ports}}</v-card-subtitle>
                  <v-card-subtitle class="mr-2 text-caption">Price per minute: {{stat.minutes_per_point}}p</v-card-subtitle>
                </v-card>
              </v-badge>
            </div>
          </div>
        </div>
      </v-row>
    </v-col>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FavoriteStations",
  mounted() {

  },
  computed:{
    ...mapGetters(['getFavorites','allStations']),
    vcardWidth(){
      switch (this.$vuetify.breakpoint.name){
        case 'xs':return 158
        default: return 200;
      }
    },
    vcardHeight(){
      switch (this.$vuetify.breakpoint.name){
        case 'xs': return 107
        default: return 130;
      }
    },
    vcardIcon(){
      switch (this.$vuetify.breakpoint.name){
        case 'xs': return 18
        default: return 27;
      }
    }
  },
  methods:{
    ...mapActions(['destroyUserFavorite']),

  },
}
</script>

<style scoped>

</style>