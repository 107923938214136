const state = {
  allPayments: {},
  currentPayment: {},
  currentPaymentId: {},
}

const getters = {
  allPayments: state => state.allPayments,
  currentPaymentId: state => state.currentPaymentId,
  currentPayment: state => state.currentPayment,
}

const actions = {
  async initiatePayment({ commit }, { points, paymentMethodId }) {
    const response = await this.$http.post('/paymentins', {
      points: points,
      payment_method_id: paymentMethodId,
    })
    commit('setCurrentPaymentId', response.data.payment.id)
    return response.data.payment
  },
  async fetchPayment({ commit }, { id, included }) {
    let queryString = '?'
    for (const relation in included) {
      queryString = queryString + included[relation] + '&'
    }
    queryString = queryString.substring(0, queryString.length - 1)
    const response = await this.$http.get('/paymentins/' + id + queryString)
    commit('setCurrentPayment', response.data)
  },
  async fetchAllPayments({ commit }) {
    const response = await this.$http.get('/paymentins')
    commit('setAllPayments', response.data)
  },
}

const mutations = {
  setCurrentPaymentId(state, payload) {
    state.currentPaymentId = payload
  },
  setCurrentPayment(state, payload) {
    state.currentPayment = payload
  },
  setAllPayments(state, payload) {
    state.allPayments = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
