<template>
  <div v-if="currentCharge.charging_port" style="height: 100%">
    <v-stepper v-model="step" alt-labels class="elevation-0">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="step > 1" step="1" :rules="shouldBeOpened">
          {{ step1Text }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2" :rules="shouldBeStarted">
          {{ step2Text }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3" :rules="shouldBeActive">
          {{ step3Text }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">
          Charge finished
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <port-opening @done="done()" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <port-open @done="done()" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <charge-active @done="done()" />
        </v-stepper-content>
        <v-stepper-content step="4">
          <charge-detail @done="done()" :successful="successful" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChargeActive from '../components/charge/ChargeActive.vue'
import ChargeDetail from '../components/charge/ChargeDetail.vue'
import PortOpen from '../components/charge/PortOpen.vue'
import PortOpening from '../components/charge/PortOpening.vue'

export default {
  components: {
    ChargeDetail,
    ChargeActive,
    PortOpen,
    PortOpening,
  },

  data() {
    return {
      shouldBeStarted: [() => this.startStatus],
      shouldBeOpened: [() => this.openStatus],
      shouldBeActive: [() => this.activeStatus],
    }
  },

  computed: {
    step1Text() {
      return this.openStatus === true ? 'Open port' : this.openStatus
    },

    step2Text() {
      return this.startStatus === true ? 'Plug device' : this.startStatus
    },

    step3Text() {
      return this.activeStatus === true ? 'Active charge' : this.activeStatus
    },

    openStatus() {
      return (
        this.currentCharge.port_opened_at !== null ||
        this.step <= 1 ||
        'Port could not open'
      )
    },

    startStatus() {
      return (
        this.currentCharge.start_at !== null ||
        this.step <= 2 ||
        'Device not plugged'
      )
    },

    activeStatus() {
      return (
        this.currentCharge.start_at !== null ||
        this.step <= 3 ||
        'Charge could not start'
      )
    },

    successful() {
      return (
        this.openStatus == true &&
        this.activeStatus == true &&
        this.startStatus == true
      )
    },

    step: function () {
      switch (this.currentCharge !== null ? this.currentCharge.status : '') {
        case 'RESERVED':
          return 1
        case 'OPEN':
          return 2
        case 'INIT CHARGING':
        case 'IDLE':
        case 'CHARGING':
          return 3
        case 'FINISHED':
        case 'ERROR':
        case 'INACTIVE':
          return 4
        default:
          return null
      }
    },

    ...mapGetters(['currentCharge', 'getCurrentStation']),
  },
  watch: {
    '$route.params': {
      handler(newValue) {
        const { chargeId } = newValue

        this.fetchCurrentCharge(chargeId).then(async () => {
          this.fetchCurrentStation(this.currentCharge.charging_port.station_id)
        })
      },
      immediate: true,
    },
  },

  async created() {
    await this.fetchCurrentCharge(this.$route.params.chargeId).then(
      async () => {
        await this.fetchCurrentStation(
          this.currentCharge.charging_port.station_id
        )
      }
    )
  },

  methods: {
    ...mapActions(['fetchCurrentCharge', 'fetchCurrentStation']),

    done() {
      this.fetchCurrentCharge(this.currentCharge.id)
    },
  },
}
</script>

<style>
</style>

