<template>
  <v-container>
    <v-row>
      <v-col align="center" justify="center">
        <v-text-field
          class="code-input"
          ref="input"
          autocomplete="one-time-code"
          placeholder="code"
          v-model="code"
          @keydown.native.enter="phoneVerify"
          @input="type = null"
          autofocus
        ></v-text-field>
        <v-btn
          color="primary"
          class="code-input-button"
          aria-label="submit code"
          @click="phoneVerify"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    phoneNumber: Object,
  },

  data() {
    return {
      code: '',
      type: null,
    }
  },

  computed: {
    ...mapGetters(['authStatus']),
  },

  mounted() {
    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', this.handleOtpAPI)
    }
    this.$refs.input.focus()
  },

  methods: {
    ...mapActions(['verifyCode']),
    async phoneVerify() {
      await this.verifyCode({
        callingCode: '+' + this.phoneNumber.countryCallingCode,
        phone: this.phoneNumber.number,
        code: this.code,
      })
        .then(() => {
          this.$emit('success')
        })
        .catch(() => {
          if (this.authStatus != 'success') {
            this.type = 'is-danger'
          }
        })
    },

    handleOtpAPI() {
      const input = document.querySelector(
        'input[autocomplete="one-time-code"]'
      )
      if (!input) {
        return
      }

      const ac = new AbortController()
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          this.code = otp.code
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
.code-input {
  max-width: 400px !important;
  margin-left: auto;
  margin-right: auto !important;
}
.code-input-button {
  margin-top: 20px;
}
</style>
