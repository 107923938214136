/* eslint-disable no-unused-vars */

const state = {
  token: getToken() || '',
  status: '',
  originalRoute: '/',
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getOriginalRoute: state => state.originalRoute,
}

const mutations = {
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state, token) {
    state.status = 'success'
    state.token = token
  },
  auth_error(state) {
    state.status = 'error'
  },
  logout(state) {
    state.status = ''
    state.token = ''
  },
  setOriginalRoute(state, payload) {
    if (payload == '/login') {
      payload = '/'
    }
    state.originalRoute = payload
  },
}

const actions = {
  async promptVerificationCode({ commit }, { callingCode, phone }) {
    await this.$http
      .post('/phone/promptverificationcode', {
        phone_prefix: callingCode,
        phone: phone,
      })
      .then(response => {
        commit('auth_request')
      })
      .catch(error => {
        commit('auth_error')
      })
  },

  async verifyCode({ commit }, { callingCode, phone, code }) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      this.$http
        .post('/phone/verify', {
          phone_prefix: callingCode,
          phone: phone,
          code: code,
        })
        .then(response => {
          let token = response.data.token
          this.$http.defaults.headers.common['Authorization'] =
            'Bearer ' + token
          this.$echo.options.auth.headers.Authorization = 'Bearer ' + token
          localStorage.setItem('token', token)
          commit('auth_success', token)
          resolve(response)
        })
        .catch(error => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(error)
        })
    })
  },
  async updateOriginalRoute({ commit }, payload) {
    commit('setOriginalRoute', payload)
  },

  logout({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('token')
      delete this.$http.defaults.headers.common['Authorization']
      delete this.$echo.options.auth.headers.Authorization
      this.$echo.leave(`user.${rootState.user.user.id}.events`)
      commit('setUser', {})
      resolve()
    })
  },
}

function getToken() {
  try {
    return localStorage.getItem('token')
  } catch (ex) {
    alert(
      'Local storage of login credentials is not supported and you may not be allowed to login. Try unblocking local storage / cookies in the browser settings or add this application to home screen.'
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
