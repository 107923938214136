import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#8eb360',
        secondary: '#eab718',
      },
      dark: {
        primary: "#8eb360",
        secondary: "#eab718",
      },
    },
  },
})
