<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        />
      </g>
    </svg>
    <span class="base-timer__label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283
const WARNING_THRESHOLD = 10

const COLOR_CODES = {
  info: {
    color: 'green',
  },
  warning: {
    color: 'orange',
    threshold: WARNING_THRESHOLD,
  },
}
export default {
  props: {
    totalMinutes: Number,
    timeRemaining: Number,
  },

  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },

    remainingPathColor() {
      const { warning, info } = COLOR_CODES
      if (this.timeRemaining / 1000 <= warning.threshold) {
        return warning.color
      }
      return info.color
    },

    formattedTimeLeft() {
      const minutes = Math.floor(this.timeRemaining / 60000)
      let seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000)
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      return `${minutes}:${seconds}`
    },

    timeFraction() {
      const rawTimeFraction =
        this.timeRemaining / 1000 / (this.totalMinutes * 60)
      return (
        rawTimeFraction - (1 / (this.totalMinutes * 60)) * (1 - rawTimeFraction)
      )
    },

  },

  watch: {
    timeRemaining: function (val) {
      if (val <= 0) {
        this.$emit('done')
      }
    },
  },
  
}
</script>

<style scoped lang="scss">
.center {
  margin: auto;
  width: 50%;
}
.base-timer {
  position: relative;
  margin: auto;
  width: 200px;
  height: 200px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
