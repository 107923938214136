<template>
  <v-container fluid fill-height class="no-padding">
    <mapbox-map />
  </v-container>
</template>

<script>
import MapboxMap from '../components/map/MapboxMap.vue'

export default {
  components: { MapboxMap },
}
</script>

<style scoped>
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin: 0px;
}
</style>
