<template>
  <v-app>
    <the-top-bar> </the-top-bar>
    <v-main>
      <v-container class="container-height" fluid>
        <transition name="slide" mode="out-in">
          <router-view />
        </transition>
        <fab-menu v-if="isAuthenticated" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TheTopBar from './components/TheTopBar.vue'
import { mapActions, mapGetters } from 'vuex'
import FabMenu from './components/FabMenu.vue'
export default {
  components: { TheTopBar, FabMenu },
  name: 'App',

  created() {
    const theme = localStorage.getItem('dark_theme')
    if (theme) {
      if (theme == 'true') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else {
      let matched = window.matchMedia('(prefers-color-scheme: dark)').matches

      if (matched) {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    }
    this.fetchUser().then(() => {
      if (this.currentUser.id) {
        this.initUserEventsChannel(this.currentUser.id).then(() => {
          this.subscribeToUserCharges()
          this.subscribeToUserUpdates()
        })
      }
    })
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
  },
  methods: {
    ...mapActions([
      'subscribeToUserCharges',
      'subscribeToUserUpdates',
      'fetchUser',
      'initUserEventsChannel',
    ]),
  },
}
</script>
<style>
/* light theme settings */
.theme--light.v-icon {
  color: black;
}
.theme--light.v-btn.v-btn--icon {
  color: black;
}
/* dark theme settings */
.theme--dark.v-data-table {
  background-color: #121212 !important;
  color: #ffffff;
}
.theme--dark.v-stepper {
  background: #121212 !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #121212 !important ;
}
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: white !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid white;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}

.theme--dark.v-list {
  background: #121212 !important;
  color: #ffffff;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #121212 !important;
  color: #ffffff;
}
/* other settings */
.container-height {
  height: 100%;
  padding: 0px;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}
</style>
