<template>
  <v-container>
    <v-row>
      <v-col>
        <v-stepper v-model="activeStep" alt-labels class="elevation-0">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="activeStep > 1" step="1">
              phone number
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="activeStep > 2" step="2">
              verify code
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"> Edit user </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <input-phone class="phoneinput-height" @submit="onPhoneSubmit" />
            </v-stepper-content>
            <v-stepper-content step="2">
              <input-code @success="codeVerified" :phoneNumber="phoneNumber" />
            </v-stepper-content>
            <v-stepper-content step="3">
              <user-edit />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputCode from '../components/login/InputCode.vue'
import InputPhone from '../components/login/InputPhone.vue'
import UserEdit from '../components/user/UserEdit.vue'
export default {
  name: 'LoginView',

  components: {
    InputPhone,
    InputCode,
    UserEdit,
  },

  data() {
    return {
      hasNavigation: false,
      phoneNumber: {},
      activeStep: 1,
    }
  },

  computed: {
    ...mapGetters(['getOriginalRoute', 'currentUser']),
  },

  methods: {
    ...mapActions([
      'fetchUser',
      'initUserEventsChannel',
      'subscribeToUserCharges',
      'subscribeToUserUpdates',
      'echo',
    ]),

    onPhoneSubmit(phoneNumber) {
      this.phoneNumber = phoneNumber
      this.activeStep = 2
    },

    async codeVerified() {
      this.fetchUser().then(() => {
        if (this.currentUser.id) {
          this.initUserEventsChannel(this.currentUser.id).then(() => {
            this.subscribeToUserCharges()
            this.subscribeToUserUpdates()
          })
        }
      })
      await this.currentUser
      let updatedAt = new Date(this.currentUser.updated_at)
      let currentDay = new Date(Date.now())
      let oneYearAgo = new Date(Date.now()).setFullYear(
        currentDay.getFullYear() - 1
      )
      if (
        updatedAt < oneYearAgo ||
        this.currentUser.created_at == this.currentUser.updated_at
      ) {
        this.activeStep = 3
      } else {
        this.$router.push(this.getOriginalRoute)
      }
    },
  },
}
</script>

<style>
.phoneinput-height {
  height: 100vh;
}
</style>
