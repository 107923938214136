<template>
  <v-row align="center" justify="center">
    <v-col align="center" justify="center">
      <v-progress-circular
        :size="100"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <p>
        Opening <b class="biggerText">port {{ currentCharge.charging_port.charging_port_number }}</b>
        <br />
        at station
        <b>{{ getCurrentStation.human_serial }}</b>
        <br />
        You may connect your device to <b class="biggerText">port {{ currentCharge.charging_port.charging_port_number }}</b>.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentCharge', 'getCurrentStation']),
  },
}
</script>

<style scoped>
  .biggerText {
    font-size: 1.2em;
  }
</style>
