/* eslint-disable no-unused-vars */

const state = {
  paymentMethods: {},
}

const getters = {
  paymentMethods: state => state.paymentMethods,
}

const actions = {
  async fetchPaymentMethods({ commit }) {
    const response = await this.$http.get('/paymentmethods')
    commit('setPaymentMethods', response.data)
  },
}

const mutations = {
  setPaymentMethods(state, payload) {
    state.paymentMethods = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
