<template>
  <v-container fluid class="no-padding-and-height"  id="mapContainer" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mapboxgl from 'mapbox-gl'

export default {
  name: 'MapboxMap',

  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      map: {},
      features: [],
    }
  },
  computed: {
    ...mapGetters(['allStations']),
    allStationsWithPositions() {
      return this.allStations.filter((station) => station.position !== null)
    },
  },

  mounted() {
    mapboxgl.accessToken = this.accessToken
    this.map = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
      center: [10.221965, 59.1319271],
      zoom: 12,
      attributionControl: false,
    })
    this.createGeojson()
    const nav = new mapboxgl.NavigationControl()
    this.map.addControl(nav, 'top-right')
    this.map.addControl(new mapboxgl.AttributionControl(), 'top-left')

    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserLocation: true,
    })

    this.map.addControl(geolocate, 'top-right')

    const map = this.map
    const router = this.$router
    const features = this.features
    this.map.on('load', function () {
      geolocate.trigger()

      map.loadImage('img/icons/ev-station-green.png', function (error, image) {
        if (error) throw error
        map.addImage('custom-marker', image)

        map.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: features,
          },
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        })

        map.addLayer({
          id: 'points',
          type: 'circle',
          source: 'points',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#51bbd6',
              100,
              '#f1f075',
              750,
              '#f28cb1',
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        })

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'points',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        })

        map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'points',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'custom-marker',
            // get the title name from the source's "title" property
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.25],
            'text-anchor': 'top',
          },
        })

        // inspect a cluster on click
        map.on('click', 'clusters', function (e) {
          var features = this.map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          })
          var clusterId = features[0].properties.cluster_id
          map
            .getSource('points')
            .getClusterExpansionZoom(clusterId, function (err, zoom) {
              if (err) return

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              })
            })
        })

        map.on('click', 'unclustered-point', function (e) {
          var station = e.features[0].properties.title.slice()
          router.push('/stations/' + station)
        })

        map.on('mouseenter', 'clusters', function () {
          map.getCanvas().style.cursor = 'pointer'
        })
        map.on('mouseleave', 'clusters', function () {
          map.getCanvas().style.cursor = ''
        })
      })
    })
  },

  methods: {
    ...mapActions(['fetchStations']),
    createGeojson() {
      this.fetchStations().then(() =>
        this.allStationsWithPositions.forEach((station) => {
          var newFeature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [station.position.lng, station.position.lat],
            },
            properties: {
              title: station.human_serial,
            },
          }
          this.features.push(newFeature)
        })
      )
    },
  },
}
</script>
<style scoped lang="scss">
.no-padding-and-height {
  padding: 0px;
  height: 100%;
  
}
.marker {
  color: #8eb360;
}
</style>
