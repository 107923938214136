<template>
  <v-data-table
    :items-per-page="5"
    @click:row="navigateToPaymentDetails"
    :headers="headers"
    :items="data"
  ></v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      headers: [
        {
          text: 'Date',
          value: 'created_at',
        },
        {
          text: 'Points purchased',
          value: 'points',
        },
      ],
      data: [],
    }
  },
  
  computed: {
    ...mapGetters(['allPayments']),
  },

  mounted() {
    this.addTableData()
  },

  methods: {
    addTableData() {
      this.allPayments.forEach((payment) => {
        this.data.push({
          created_at: this.format(payment.created_at),
          points: '+' + payment.points + 'p',
          id: payment.id,
        })
      })
    },
    
    format(date) {
      return dayjs(date).format('D.MMM YYYY')
    },

    navigateToPaymentDetails(row) {
      this.$router.push(`/payment/${row.id}`)
    },
  },

}
</script>

<style>
</style>