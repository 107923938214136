/* eslint-disable no-unused-vars */

const state = {
  stations: [],
  station: {},
  error: false,
}

const getters = {
  allStations: state => state.stations,
  getCurrentStation: state => state.station,
  getError: state => state.error,
}

const actions = {
  async fetchStations({ commit }) {
    const response = await this.$http.get('/stations')
    commit('setStations', response.data)
  },
  async fetchCurrentStationByHumanSerial({ commit }, serial) {
    try {
      if (state.station.human_serial !== serial) {
        commit('setStation', {})
      }
      const response = await this.$http.get(
        `/stations?human_serial=${serial}&withOpeningHours`
      )
      if (response.data.length < 1) {
        commit('setError', true)
      } else {
        commit('setStation', response.data[0])
      }
    } catch (error) {
      if (error) {
        commit('setError', true)
      }
    }
  },
  async fetchCurrentStation({ commit }, id) {
    try {
      if (state.station.id !== id) {
        commit('setStation', {})
      }
      const response = await this.$http.get(`/stations/${id}?withOpeningHours`)
      commit('setStation', response.data)
    } catch (error) {
      if (error.response.status == 404) {
        commit('setError', true)
      }
    }
  },

  async initStationEventsChannel({ commit }, stationId) {
    this.$stationEventsChannel = this.$echo.channel('station.' + stationId)
  },

  subscribeToStationUpdates({ commit }) {
    this.$stationEventsChannel.listen('StationUpdated', payload => {
      commit('setStation', payload.station)
    })
  },

  leaveStationEventsChannel({ commit }, stationId) {
    this.$echo.leave('station.' + stationId)
  },

  async resetError({ commit }) {
    commit('setError', false)
  },
}

const mutations = {
  setStations: (state, stations) => (state.stations = stations),
  setStation: (state, station) => (state.station = station),
  setError: (state, error) => (state.error = error),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
