<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <h1 class="title">Payment Details</h1>
        <v-icon >mdi-currency-usd</v-icon>
        <p>{{ currentPayment.points }} points</p>
        <v-icon >mdi-clock-outline</v-icon>
        <p>{{ currentPayment.created_at | format }}</p>
        <v-icon > mdi-receipt</v-icon>
        <p v-if="currentPayment.currency">
          {{ currentPayment.cost }} {{ currentPayment.currency.short_name }}
        </p>
        <v-icon >mdi-credit-card-settings-outline</v-icon>
        <p v-if="currentPayment.payment_method">
          {{ currentPayment.payment_method.name }}
        </p>
        <v-btn icon  to="/history" class="mt-12">
          <v-icon size="50">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
export default {
  filters: {
    format(date) {
      return dayjs(date).format('D.MMM YYYY')
    },
  },

  computed: {
    ...mapGetters(['currentPayment']),
  },

  created() {
    this.fetchPayment({
      id: this.$route.params.id,
      included: ['withCurrency', 'withPaymentMethod'],
    })
  },

  methods: {
    ...mapActions(['fetchPayment']),
  },
  
}
</script>

<style></style>
