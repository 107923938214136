<template>
  <v-container>
    <v-row>
      <v-col v-if="loading">
        <station-skeleton />
      </v-col>
      <v-col v-else>
        <v-col v-if="getError == true">
          <station-not-found />
        </v-col>
        <v-col v-else>
          <station-component />
        </v-col> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StationSkeleton from '../components/station/StationSkeleton.vue'
import StationNotFound from '../components/station/StationNotFound.vue'
import StationComponent from '../components/station/StationComponent.vue'

export default {
  data() {
    return {
      loading: false,
    }
  },

  components: { StationComponent, StationNotFound, StationSkeleton },

  computed: {
    ...mapGetters(['getCurrentStation', 'getError']),
  },

  async created() {
    this.loading = true
    await this.resetError()
    await this.fetchCurrentStationByHumanSerial(this.$route.params.humanSerial)
    this.initStationEventsChannel(this.getCurrentStation.id)
    this.subscribeToStationUpdates()
    this.loading = false
  },

  beforeDestroy() {
    this.leaveStationEventsChannel(this.getCurrentStation.id)
  },

  methods: {
    ...mapActions([
      'fetchCurrentStationByHumanSerial',
      'resetError',
      'initStationEventsChannel',
      'subscribeToStationUpdates',
      'leaveStationEventsChannel',
    ]),
  },
}
</script>

<style></style>
