/* eslint-disable no-unused-vars */

const state = {
  currency: {},
}

const getters = {
  currency: state => state.currency,
}

const actions = {
  async fetchCurrency({ commit }) {
    const response = await this.$http.get('/currencies/?withDiscounts')
    commit('setCurrency', response.data[0])
  },
}

const mutations = {
  setCurrency(state, payload) {
    state.currency = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
