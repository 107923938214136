<template>
  <v-menu tile :nudge-width="300" offset-y :close-on-click="closeOnClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon x-large v-bind="attrs" v-on="on">
        <v-avatar size="48">
          <v-icon > mdi-account-circle </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="primary" class="mb-1">
            <v-icon dark > mdi-account-circle </v-icon>
          </v-avatar>
          <h3
            class="mb-1"
            v-if="currentUser.first_name || currentUser.last_name"
          >
            {{ currentUser.first_name }} {{ currentUser.last_name }}
          </h3>
          <h3 class="mb-1" v-else>No name</h3>
          <v-row>
            <v-col class="center-col">
              <p class="caption mb-1 text-left">
                <v-icon small> mdi-cellphone</v-icon>
                {{ currentUser.phone }}
              </p>
              <p class="caption mb-1 text-left">
                <v-icon small >mdi-email</v-icon>
                {{ currentUser.email }}
              </p>
              <p class="caption mb-1 text-left">
                <v-icon small >mdi-currency-usd</v-icon>
                {{ currentUser.points ? currentUser.points : 0 }} points
              </p>
            </v-col>
          </v-row>
          <div v-if="isAuthenticated">
            <v-divider class="my-3"></v-divider>
            <v-btn
              class="button-margin-left"
              depressed
              rounded
              text
              to="/history"
            >
              <v-icon>mdi-history</v-icon> View history
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text to="/user/edit">
              <v-icon>mdi-pencil-outline</v-icon> Edit user
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text @click="logoutUser">
              <v-icon>mdi-logout</v-icon>Sign out
            </v-btn>
          </div>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      closeOnClick: true,
    }
  },
  
  computed: mapGetters(['currentUser', 'isAuthenticated']),
  
  methods: {
    ...mapActions(['logout']),
    logoutUser() {
      this.logout()
      this.$router.push('/login')
    },
  },

}
</script>

<style scoped>
.button-margin-left {
  margin-left: 30px;
}
.center-col {
  margin-left: auto;
  margin-right: auto;
  max-width: 40% !important;
}
</style>